:root {
    --background: #130f23;
    --violet2: #1C1932;
    --softGrey: #F6F7FB;
    --hardGrey: #8B939F;
    --darkBlue: #2C304A;
    --darkBlue2: #1B1E34;
    --greyBlue: #4C4967;
    --lightBlue: #45A8FB;
    --blue: #306BF3;
    --mossGreen: #149971;
    --white: #ffffff;
    --darkGrey: #9A9DA3;
    --greenSuccess: #36C970;
    --redError: #EF5252;
    --red2: #c23a3a;
}